<template>
  <div class="add-shipment">
    <!-- static section -->

    <div class="max-width-small my-10">
      <v-form v-model="valid" @submit.prevent="handleSubmit" ref="tripform">
        <v-row class="mt-14">
          <!-- origin address -->

          <!-- country -->
          <v-col cols="12">
              <span class="font-30 primary--text font-500">{{
                $t("originAddress")
              }}</span>
          </v-col>
          <v-col md="6" cols="12">
            <base-select
              :placeholder="$t('country')"
              :items="countries"
              v-model="obj.origin_country"
              @input="countryHandler($event, 'origin')"
            ></base-select
          ></v-col>
          <!-- city -->
          <v-col md="6" cols="12"
            ><base-select
              :placeholder="$t('city')"
              :items="originCities"
              v-model="obj.origin_city"
            ></base-select
          ></v-col>

          <!-- Distination Address -->
          <!-- country -->
          <v-col cols="12">
              <span class="font-30 primary--text font-500">{{
                $t("distinationAddress")
              }}</span>
            </v-col>
          <v-col md="6" cols="12">
            <base-select
              :placeholder="$t('country')"
              :items="countries"
              v-model="obj.distination_country"
              @input="countryHandler($event, 'distination')"
            ></base-select
          ></v-col>
          <!-- city -->
          <v-col md="6" cols="12"
            ><base-select
              :placeholder="$t('city')"
              :items="distinationCities"
              v-model="obj.distination_city"
            ></base-select
          ></v-col>

          <!-- date -->
          <v-col sm="6" cols="12">
            <base-date
              :placeholder="$t('date')"
              v-model="obj.date"
              @handleDate="obj.date = $event"
            ></base-date>
          </v-col>

          <!-- Frequency -->
          <v-col sm="6" cols="12">
            <base-select
              :placeholder="$t('frequency')"
              :items="$vuetify.rtl ? frequencyAr : frequency"
              v-model="obj.frequency"
            ></base-select>
          </v-col>

          <!-- Weight -->
          <v-col sm="6" cols="12">
            <base-input
              :placeholder="$t('weight')"
              :isWeight="true"
              v-model="obj.weight"
              itemText="title"
              itemValue="weight"
              type="number"
            ></base-input>
          </v-col>

          <!-- Category -->
          <v-col sm="6" cols="12">
            <base-select
              :placeholder="$t('categories')"
              :items="categories"
              v-model="obj.category"
              :multiple="true"
            ></base-select>
          </v-col>
        </v-row>

        <div class="text-center mt-5" :class="isMobile ? '' : 'mt-8'">
          <v-btn
            color="primary"
            type="submit"
            :disabled="!valid || obj.category.length == 0"
          >
            <span>{{ $t("addTrip") }}</span>
          </v-btn>
        </div>
      </v-form>
    </div>

    <!-- new trip dialog -->
    <v-dialog v-model="addDialog" max-width="400" content-class="white">
      <add-shipment
        :title="$t('tripSuccessfullyAdded')"
        :goTo="$t('goToMyTrips')"
        @close="$router.push('/profile/trips')"
      ></add-shipment>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddShipment from "../../components/dialogs/AddShipment.vue";
export default {
  components: {
    AddShipment,
  },
  data: () => ({
    addDialog: false,
    valid: false,
    obj: { date: "" },
    originCities: [],
    distinationCities: [],
  }),
  computed: {
    ...mapGetters([
      "frequency",
      "frequencyAr",
      "countries",
      "categories",
    ]),
  },
  methods: {
    countryHandler(event, type) {
      if (type == "distination") {
        this.distinationCities = this.countries.filter(
          (country) => country.id == event
        )[0].cities;
      } else {
        this.originCities = this.countries.filter(
          (country) => country.id == event
        )[0].cities;
      }
    },
    async handleSubmit() {
      let formData = new FormData();

      formData.append("origin_country_id", this.obj.origin_country);
      formData.append("destination_country_id", this.obj.distination_country);
      formData.append("origin_city_id", this.obj.origin_city);
      formData.append("destination_city_id", this.obj.distination_city);
      formData.append("expected_date", this.obj.date);
      formData.append("frequency", this.obj.frequency);
      formData.append("category_ids", "[" + this.obj.category + "]");
      formData.append("weight", this.obj.weight);

      let { data } = await this.$axios.post("trip", formData);
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Trip Added Successfully",
          color: "success",
        });
        this.$refs.tripform.reset();
        this.addDialog = true;
      } else {
        this.$store.dispatch("showSnack", {
          text: data.message,
          color: "error",
        });
      }
    },
  },
  beforeRouteEnter(_, _2, next) {
    if (localStorage.getItem("role") == "bringer") {
      next();
    } else if (
      localStorage.getItem("role") == "guest" ||
      localStorage.getItem("role") == "sender"
    ) {
      next("/verification");
    } else {
      next("/sign-in");
    }
  },
};
</script>

<style lang="scss"></style>
