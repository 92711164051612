<template>
  <div class="verify-dialog">
    <div class="px-10 text-center">
      <span class="mt-10 d-block font-20 black--text font-500">
        {{ title }}</span
      >
      <!-- Your shipment has been successfully added</span -->
      <img
        class="my-14"
        width="100"
        height="100"
        :src="require('@/assets/images/icons/Done.png')"
        alt=""
      />
      <v-btn
        class="c-btn mb-10"
        color="primary"
        min-width="240"
        height="40"
        @click="$emit('close')"
        ><span class="white--text font-300 font-20">{{
         goTo 
        }}</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title:{
      type: String,
      default: ''
    },
    goTo:{
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="scss"></style>
